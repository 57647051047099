import React, { useState } from 'react';

const SearchParticipantByName = () => {
  const [numberCard, setNumberCard] = useState('');
  const [participant, setParticipant] = useState(null);
  const [error, setError] = useState('');

  const handleFetchParticipant = async () => {
    try {
      const response = await fetch(
        `https://api.businessforreal.com.br/api/nfc/participant/${numberCard}`,
      );
      const data = await response.json();

      if (response.ok) {
        setParticipant(data);
        setError('');
      } else {
        setParticipant(null);
        setError('Nenhum participante encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar participante:', error);
      setParticipant(null);
      setError(
        'Erro ao buscar participante. Verifique o console para mais detalhes.',
      );
    }
  };

  return (
    <div className="fetch-participant-container">
      <h1>Buscar Participante por Número de Cartão</h1>
      <div>
        <label>Número do Cartão:</label>
        <input
          type="text"
          value={numberCard}
          onChange={(e) => setNumberCard(e.target.value)}
        />
        <button onClick={handleFetchParticipant}>Buscar</button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {participant && (
        <div className="participant-details">
          <h2>Detalhes do Participante</h2>
          <p>Nome: {participant.name}</p>
          <p>Foto: {participant.photo}</p>
          <p>ID do Cartão: {participant.idCard}</p>
          <p>Numero do Cartão: {participant.numberCard}</p>
        </div>
      )}
    </div>
  );
};

export default SearchParticipantByName;
