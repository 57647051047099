// No arquivo SearchParticipantByCard.js

import React, { useState } from 'react';

const SearchParticipantByCard = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [participant, setParticipant] = useState(null);
  const [error, setError] = useState('');

  const handleSearchParticipantByCard = async () => {
    try {
      const response = await fetch(
        `https://api.businessforreal.com.br/api/nfc/participant/${cardNumber}`,
      );

      if (response.ok) {
        const data = await response.json();
        setParticipant(data);
        setError('');
      } else {
        setParticipant(null);
        setError(
          'Participante não encontrado. Verifique o número do cartão e tente novamente.',
        );
      }
    } catch (error) {
      console.error('Erro ao buscar participante:', error);
      setParticipant(null);
      setError(
        'Erro ao buscar participante. Verifique o console para mais detalhes.',
      );
    }
  };

  return (
    <div className="search-participant-by-card-container">
      <h1>Buscar Participante por Número do Cartão</h1>
      <div>
        <label>Número do Cartão:</label>
        <input
          type="text"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
        />
        <button onClick={handleSearchParticipantByCard}>Buscar</button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {participant && (
        <div className="participant-info">
          <h2>Informações do Participante</h2>
          <p>
            <strong>Nome:</strong> {participant.name}
          </p>
          <p>
            <strong>ID do Cartão:</strong> {participant.idCard}
          </p>
          <p>
            <strong>Numero do Cartão:</strong> {participant.numberCard}
          </p>
          <p>
            <strong>Foto:</strong>{' '}
            <img src={participant.photo} alt={participant.name} />
          </p>
        </div>
      )}
    </div>
  );
};

export default SearchParticipantByCard;
