import React, { useEffect, useState } from 'react';
import './ListParticipant.css';

const Participants = () => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    // Requisição para obter todos os participantes
    fetch('https://api.businessforreal.com.br/api/nfc/participant')
      .then((response) => response.json())
      .then((data) => {
        const sortedParticipants = [...data].sort(sortParticipants);
        setParticipants(sortedParticipants);
      })
      .catch((error) => {
        console.error('Erro ao buscar participantes:', error);
      });
  }, []);

  return (
    <div className="participants-container">
      <h1>Participantes</h1>
      <table className="participants-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>ID do Cartão</th>
            <th>Número do Cartão</th>
            <th>Foto</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => (
            <tr key={participant._id}>
              <td>{participant.name}</td>
              <td>{participant.idCard}</td>
              <td>{participant.numberCard}</td>
              <td>
                <img
                  src={participant.photo}
                  alt={participant.name}
                  className="participant-image"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <a href="/" className="back-link">
        Voltar para a Página Inicial
      </a>
    </div>
  );
};

const sortParticipants = (a, b) => {
  if (a.numberCard !== b.numberCard) {
    return a.numberCard - b.numberCard;
  }
  return a.name.localeCompare(b.name);
};

const handleFilterChange = (field, value) => {
  setFilter((prevFilter) => ({ ...prevFilter, [field]: value }));
};

const applyFilter = (participant) => {
  const { name, numberCard } = filter;
  return (
    (name === '' ||
      participant.name.toLowerCase().includes(name.toLowerCase())) &&
    (numberCard === '' ||
      participant.numberCard.toString().includes(numberCard))
  );
};

export default Participants;
