import React from 'react';import './Welcome.css';

const WelcomePage = ({ photo, name, entryTime }) => {

  return (
    <div className="container">
      <div className="background-image" style={{ backgroundImage: `url(${process.env.REACT_APP_BACKGROUND} )` }}>
      <div className="welcome-container">
          <div className="content">
            <div>
              <img class="mini-logo" src={process.env.REACT_APP_MINI_LOGO} alt="logo"/>
            </div>
            <img className="imagem-redonda" src={photo} alt="Imagem do participante" />
            <h2 className="messages">SEJA BEM-VINDO!</h2>
            <h2 className="name">{name}</h2>
            <h4 className="check">{entryTime}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

