import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import NotFound from './NotFound';
import CreateParticipant from './CreateParticipant';
import ListParticipant from './ListParticipant';
import SearchParticipantByName from './SearchParticipantByName';
import DeleteParticipantByName from './DeleteParticipantByName';
import SearchParticipantByCard from './SearchParticipantByCard';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={<App />} />
        <Route path="/createparticipant" element={<CreateParticipant />} />
        <Route path="/listParticipant" element={<ListParticipant />} />
        <Route path="/searchParticipantByName" element={<SearchParticipantByName />} />
        <Route path="/deleteParticipantByName" element={<DeleteParticipantByName />} />
        <Route path="/searchParticipantByCard" element={<SearchParticipantByCard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
