import React, { useState } from 'react';
import './CreateParticipant.css';

const CreateParticipant = () => {
  const [name, setName] = useState('');
  const [photoLink, setPhotoLink] = useState('');
  const [idCard, setIdCard] = useState('');
  const [numberCard, setNumberCard] = useState('');
  const [message, setMessage] = useState('');

  const handleCreateParticipant = async () => {
    if (!name || !photoLink || !idCard || !numberCard) {
      setMessage('Por favor, preencha todos os campos.');
      return;
    }

    try {
      const responseCreate = await fetch(
        process.env.REACT_APP_URL_API + '/participant/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: name,
            photo: 'https://businessforreal.com.br/fotos/' + photoLink + '.png',
            idCard: idCard,
            numberCard: numberCard,
          }),
        },
      );

      const responseCheckin = await fetch(
        process.env.REACT_APP_URL_API + '/checkin/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            idCard: idCard,
          }),
        },
      );

      if (responseCreate.ok && responseCheckin.ok) {
        setMessage('Participante criado com sucesso!');
        setName('');
        setPhotoLink('');
        setIdCard('');
        setNumberCard('');
      } else {
        setMessage('Erro ao criar participante. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao criar participante:', error);
      setMessage(
        'Erro ao criar participante. Verifique o console para mais detalhes.',
      );
    }
  };

  return (
    <div className="create-participant-form">
      <h2>Criar Novo Participante</h2>
      <div>
        <label>Nome Completo:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label>
          Nome da Foto ( Colocar apenas o nome, exemplo:
          nome_sobrenome_nomefinal )
        </label>
        <input
          type="text"
          value={photoLink}
          onChange={(e) => setPhotoLink(e.target.value)}
        />
      </div>
      <div>
        <label>ID da Tag do Cartão:</label>
        <input
          type="text"
          value={idCard}
          onChange={(e) => setIdCard(e.target.value)}
        />
      </div>
      <div>
        <label>Número do Cartão:</label>
        <input
          type="text"
          value={numberCard}
          onChange={(e) => setNumberCard(e.target.value)}
        />
      </div>
      <button onClick={handleCreateParticipant}>Criar Participante</button>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default CreateParticipant;
