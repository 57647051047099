import './App.css';

import React, {
  useEffect,
  useState,
} from 'react';

import socketIo from 'socket.io-client';

import RestScreenEvent from './RestScreenEvent';
import ThankYou from './ThankYou';
import Welcome from './Welcome';

const FormatData = require('./helpers/FormatData');

const URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:3334';

const Home = () => {
  const [entryTime, setEntryTime] = useState(null);
  const [exitTime, setExitTime] = useState(null);
  const [page, setPage] = useState('RestScreenEvent'); 
  const [name, setName] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const socket = socketIo(URL, { transports: ['websocket'] });
      
      socket.on('checkin', (validation) => {
        console.log(validation);
        socket.on('checkin_updated', (participant) => {
          console.log('checkin_updated');
          const { name, photo, entryTime, exitTime } = participant;
          setName(name);
          setPhoto(photo);
          if (entryTime && exitTime) {
            setPage('ThankYou');
            setEntryTime(entryTime);
            setExitTime(exitTime);
            setTimeout(() => setPage('RestScreenEvent'), 10000);
            setIsLoading(true);
          } else if (entryTime) {
            setPage('Welcome');
            setEntryTime(entryTime);
            setTimeout(() => setPage('RestScreenEvent'), 10000);
            setIsLoading(true);
         }
          setIsLoading(false);
          socket.emit('sucesso', 'Conexão estabelecida com sucesso.');
        });
      });
      
      return () => {
        socket.disconnect();
      };
    } catch (error) {
      setError('Erro ao conectar ao servidor de socket.');
      console.error(error);
    }
  }, []);

  return (
    <div className="App">
      {error && <div className="error-message">{error}</div>}
      {!isLoading && page === 'ThankYou' ? (
        <div >
          <ThankYou photo={photo} name={name} exitTime={FormatData(exitTime)} />
        </div>
      ) : !isLoading && page === 'Welcome' ? (
        <div>
          <Welcome photo={photo} name={name} entryTime={FormatData(entryTime)} />
        </div>
      ) : (
        <RestScreenEvent />
      )}
    </div> 
  );
};

export default Home;
