const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês começa do zero, então é necessário adicionar 1
    const ano = data.getFullYear().toString().slice(-2);
    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    
    return `${horas}:${minutos} - ${dia}/${mes}/${ano}`;
}
  
module.exports = formatarData;