// No arquivo DeleteParticipant.js

import React, { useState } from 'react';

const DeleteParticipantByName = () => {
  const [numberCard, setNumberCard] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleDeleteParticipant = async () => {
    try {
      const response = await fetch(
        `https://api.businessforreal.com.br/api/nfc/participant/${numberCard}`,
        {
          method: 'DELETE',
        },
      );

      if (response.ok) {
        setMessage('Participante excluído com sucesso!');
        setError('');
      } else {
        setMessage('');
        setError(
          'Erro ao excluir participante. Verifique o número do cartão e tente novamente.',
        );
      }
    } catch (error) {
      console.error('Erro ao excluir participante:', error);
      setMessage('');
      setError(
        'Erro ao excluir participante. Verifique o console para mais detalhes.',
      );
    }
  };

  return (
    <div className="delete-participant-container">
      <h1>Excluir Participante por Número de Cartão</h1>
      <div>
        <label>Número do Cartão:</label>
        <input
          type="text"
          value={numberCard}
          onChange={(e) => setNumberCard(e.target.value)}
        />
        <button onClick={handleDeleteParticipant}>Excluir</button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {message && <div className="success-message">{message}</div>}
    </div>
  );
};

export default DeleteParticipantByName;
