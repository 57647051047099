import React from "react";
import "./RestScreenComponent.css";

const RestScreenEvent = () => {
  return (
    <div
      className="background-image"
      style={{ backgroundImage: `url(${process.env.REACT_APP_BACKGROUND} )` }}
    >
      <div className="rest-screen">
        <img
          src={process.env.REACT_APP_LOGO}
          alt="Logo da Empresa"
          className="company-logo"
        />
      </div>
    </div>
  );
};

export default RestScreenEvent;
